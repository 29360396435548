import Button from '@/components/ui/Button'
import Icon from '@/components/ui/Icon'
import Text from '@/components/ui/Text'
import { bugsnagNotification } from '@/configs/setupBugSnags'
import {
  renderButton,
  renderStatus,
  renderSubscriptionStatus,
  renderText
} from '@/pages/user/helper'
import { useUpdateUserStatusMutation } from '@/store/slice/stripe.service'
import { useGetUsersQuery, useLazyGetUsersQuery } from '@/store/slice/user.service'
import type { UserAccount } from '@/types/user.type'
import { OnChange, PayloadErrorMessage } from '@/types/utils.type'
import { coreError } from '@/utils/helpers'
import { ORDER_BY_LOWERCASE, PAGINATION } from '@/utils/variables'
import {
  Flex,
  GetRef,
  Input,
  Modal,
  PaginationProps,
  Table,
  TableColumnType,
  TableColumnsType
} from 'antd'
import { FilterDropdownProps, FilterValue, SorterResult } from 'antd/es/table/interface'
import cls from 'classnames'
import { debounce, head } from 'lodash'
import moment from 'moment'
import queryString from 'query-string'
import { useRef, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import st from './styles.module.scss'

type InputRef = GetRef<typeof Input>
type DataIndex = keyof UserAccount.Type
type Options = {
  status?: string
} & UserAccount.FilterUser

const User = () => {
  let [searchParams] = useSearchParams()
  const { pathname } = useLocation()

  const [options, setOptions] = useState<Options>({
    limit: Number(searchParams.get('limit')) || PAGINATION.PAGE_SIZE,
    page: Number(searchParams.get('page')) || PAGINATION.PAGE,
    status: searchParams.get('subscription_status') || undefined
  })

  const searchInput = useRef<InputRef>(null)

  const { data, isFetching } = useGetUsersQuery(options)
  const [getUsers] = useLazyGetUsersQuery()
  const [onUpdate] = useUpdateUserStatusMutation()

  const onChangeTable = (
    pagination: PaginationProps,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserAccount.Type>
  ) => {
    const search = {
      name: searchParams.get('name') || head(filters.name),
      subscription_status:
        searchParams.get('subscription_status') || filters.subscription_status?.join(','),
      subscription_type:
        searchParams.get('subscription_type') || filters.subscription_type?.join(','),
      email: head(filters.email) || searchParams.get('email') || undefined,
      company_name: head(filters.company_name) || searchParams.get('company_name') || undefined,
      order_by:
        ORDER_BY_LOWERCASE[`${sorter.order}` as 'descend' | 'ascend'] ||
        (searchParams.get('order_by') as 'ASC' | 'DESC') ||
        undefined,
      sort_by: sorter.order ? sorter.columnKey || searchParams.get('sort_by') : undefined,
      limit: pagination?.pageSize || Number(searchParams.get('limit')),
      page: pagination?.current || Number(searchParams.get('page')),
      status: searchParams.get('status') || filters.status?.join(',') || undefined
    }

    const string = queryString.stringify({ ...options, ...search })
    if (typeof window !== 'undefined') {
      window.history.pushState({}, '', `${pathname}?${string}`)
    }
    setOptions((prev) => ({ ...prev, ...search } as Options))
  }

  const handleSearch = (confirm: FilterDropdownProps['confirm']) => confirm()

  const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<UserAccount.Type> => {
    // console.log(dataIndex)
    // console.log(typeof dataIndex)
    return {
      filterDropdown: ({ setSelectedKeys, confirm, close }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder='Search'
            // onBlur={() => handleSearch(confirm)}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
              debounce(() => handleSearch(confirm), 2000)()
            }}
            // defaultValue={(options as any[`${typeof dataIndex}`] as any).limit}
            // onPressEnter={() => handleSearch(confirm)}
            style={{ marginBottom: 8, display: 'block', height: 32 }}
          />
          <Flex justify='flex-end'>
            <Button type='link' size='small' onClick={() => close()}>
              Close
            </Button>
          </Flex>
        </div>
      ),
      filterIcon: () => <Icon icon='search' color='--sub-2' />,
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100)
        }
      }
    }
  }

  const renderHeaderTable = (name: string) => {
    return <Text size='14' content={name} color='--secondary-2' fontWeight={600} />
  }

  const renderSortIcon = (sortOrder: string | null) =>
    sortOrder === 'descend' ? (
      <Icon icon='desc' color='--sub-2' />
    ) : (
      <Icon icon='asc' color='--sub-2' />
    )

  const onChangeStatus = (record: UserAccount.Type) => {
    console.log(record)
    Modal.confirm({
      title: <Text size='16' content='Confirm' fontWeight={600} color='--secondary-2' />,
      content: <Text size='14' content={renderText(record.status)} color='--sub-1' />,
      centered: true,
      closable: true,
      className: cls(st.modal__confirm, st.approve),
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      onOk: async () => {
        try {
          const response = await onUpdate({
            id: record?.id,
            status: record.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
          }).unwrap()
          if (response) getUsers(options)
        } catch (error) {
          bugsnagNotification(coreError(error as PayloadErrorMessage))
        }
      }
    })
  }

  const handleChange: OnChange<UserAccount.Type> = (pagination, filters, sorter) => {
    if (onChangeTable) onChangeTable(pagination, filters, sorter as SorterResult<UserAccount.Type>)
  }

  const columns: TableColumnsType<UserAccount.Type> = [
    {
      title: renderHeaderTable('User name'),
      key: 'name',
      width: 200,
      ...getColumnSearchProps('name'),
      sorter: () => 0,
      sortIcon: ({ sortOrder }) => renderSortIcon(sortOrder),
      sortDirections: ['descend', 'ascend'],
      render: (record: UserAccount.Type) => <Text size='14' content={record.name} />
    },
    {
      title: renderHeaderTable('Company name'),
      key: 'company_name',
      width: 300,
      ...getColumnSearchProps('company_name'),
      sorter: () => 0,
      sortIcon: ({ sortOrder }) => renderSortIcon(sortOrder),
      sortDirections: ['descend', 'ascend'],
      render: (record: UserAccount.Type) => <Text size='14' content={record?.company_name} />
    },
    {
      title: renderHeaderTable('Email address'),
      key: 'email',
      width: 300,
      ...getColumnSearchProps('email'),
      sorter: () => 0,
      sortIcon: ({ sortOrder }) => renderSortIcon(sortOrder),
      sortDirections: ['descend', 'ascend'],
      render: (record: UserAccount.Type) => <Text size='14' content={record.email} />
    },
    {
      title: renderHeaderTable('Subscription Status'),
      key: 'subscription_status',
      filters: [{ text: 'Trial', value: 'TRIAL' }],
      width: 200,
      render: (record: UserAccount.Type) => (
        <Flex justify='center'>{renderSubscriptionStatus(record.subscription_status)}</Flex>
      )
    },
    {
      title: renderHeaderTable('Subscription Type'),
      key: 'subscription_type',
      filters: [
        { text: 'Month', value: 'MONTH' },
        { text: 'Year', value: 'YEAR' },
        { text: 'Canceled', value: 'CANCELED' }
      ],
      width: 200,
      render: (record: UserAccount.Type) => (
        <Flex justify='center'>{renderSubscriptionStatus(record.subscription_type)}</Flex>
      )
    },
    {
      title: renderHeaderTable('Status'),
      key: 'status',
      filters: [
        { text: 'Inactive', value: 'INACTIVE' },
        { text: 'Active', value: 'ACTIVE' },
        { text: 'Expired', value: 'EXPIRED' }
      ],
      width: 180,
      render: (record: UserAccount.Type) => (
        <Flex justify='center'>{renderStatus(record.status)}</Flex>
      )
    },
    {
      title: renderHeaderTable('Joined Date'),
      key: 'joined_date',
      width: 150,
      sorter: () => 0,
      sortIcon: ({ sortOrder }) => renderSortIcon(sortOrder),
      sortDirections: ['descend', 'ascend'],
      render: (record: UserAccount.Type) =>
        record.joined_date && (
          <Text size='14' content={moment(record.joined_date).format('DD/MM/YYYY')} />
        )
    },
    {
      title: renderHeaderTable('Expired Date'),
      key: 'expired_date',
      width: 150,
      sorter: () => 0,
      sortIcon: ({ sortOrder }) => renderSortIcon(sortOrder),
      sortDirections: ['descend', 'ascend'],
      render: (record: UserAccount.Type) =>
        record.expired_date && (
          <Text size='14' content={moment(record.expired_date).format('DD/MM/YYYY')} />
        )
    },
    {
      title: renderHeaderTable('Last Login Date'),
      key: 'last_login',
      width: 180,
      sorter: () => 0,
      sortIcon: ({ sortOrder }) => renderSortIcon(sortOrder),
      sortDirections: ['descend', 'ascend'],
      render: (record: UserAccount.Type) =>
        record.last_login && (
          <Text size='14' content={moment(record.last_login).format('DD/MM/YYYY')} />
        )
    },
    {
      title: renderHeaderTable('Action'),
      align: 'center',
      key: 'action',
      width: 150,
      fixed: 'right',
      render: (record: UserAccount.Type) =>
        record.subscription_type !== 'CANCELED' && (
          <Flex justify='center'>{renderButton(record, () => onChangeStatus(record))}</Flex>
        )
    }
  ]

  const renderHeader = () => (
    <Flex className={st.header} justify='space-between' align='center'>
      <Text size='24' content='User Management' fontWeight={600} color='--secondary-2' />
    </Flex>
  )

  return (
    <Flex vertical className='layout-container'>
      {renderHeader()}
      <div className={st.content}>
        <Table
          bordered
          columns={columns}
          dataSource={data?.data!}
          onChange={handleChange}
          loading={isFetching}
          rowKey='id'
          scroll={{ x: '100%', y: 'calc(100vh - 295px)' }}
          pagination={{
            total: (data as any)?.meta?.pagination?.total,
            current: (data as any)?.meta?.pagination?.current_page,
            pageSize: (data as any)?.meta?.pagination?.per_page,
            showSizeChanger: true,
            showTotal: (total: number, [start, end]: number[]) =>
              `Showing ${start} to ${end} of ${total} entries`
          }}
        />
      </div>
    </Flex>
  )
}

export default User
