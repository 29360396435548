import axiosBaseQuery from '@/store/axiosBaseQuery'
import type { CampaignType } from '@/types/campaign.type'
import type { FilterLead, ImportDataType, LeadSalesForceType, LeadType } from '@/types/lead.type'
import type { DataResponse } from '@/types/utils.type'
import { createApi } from '@reduxjs/toolkit/query/react'

export const leadSlice = createApi({
  reducerPath: 'leadSlice',
  tagTypes: ['Leads'],
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getLeads: build.query<DataResponse<LeadType[]>, FilterLead | void>({
      query: (params) => ({
        url: '/api/leads',
        params
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => {
        if (result) {
          const final = [
            ...result.data.map(({ id }) => ({ type: 'Leads' as const, id: id as string })),
            { type: 'Leads' as const, id: 'LIST' }
          ]
          return final
        }
        return [{ type: 'Leads' as const, id: 'LIST' }]
      }
    }),
    getDetailLead: build.query<DataResponse<LeadType>, string>({
      query: (id) => ({
        url: `/api/leads/${id}`
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => [{ type: 'Leads' as const, id: result?.data.id as string }]
    }),
    getDetailLeadHistory: build.query<DataResponse<CampaignType[]>, string>({
      query: (id) => ({
        url: `/api/lead-histories/${id}`
      }),
      keepUnusedDataFor: 0
    }),
    addLead: build.mutation<DataResponse<LeadType>, Omit<LeadType, 'id'>>({
      query: (body) => ({
        url: '/api/leads',
        method: 'POST',
        data: body,
        isNotification: false
      }),
      invalidatesTags: (result, error, body) => [{ type: 'Leads', id: 'LIST' }]
    }),
    updateLead: build.mutation<DataResponse<LeadType>, LeadType>({
      query: (data) => ({
        url: `/api/leads/${data.id}`,
        method: 'PUT',
        data
      }),
      invalidatesTags: (result, error, body) => {
        if (error) {
          return []
        }

        return [{ type: 'Leads', id: body.id as string }]
      }
    }),
    deleteLead: build.mutation<DataResponse<CampaignType[]>, string>({
      query: (id) => ({
        url: `/api/leads/${id}`,
        method: 'DELETE',
        data: {
          id
        }
      }),
      invalidatesTags: (result, error, id) => {
        if (result && result.status === 200) {
          return []
        }

        return [{ type: 'Leads', id }]
      }
    }),
    getExistLeads: build.query<DataResponse<LeadType[]>, FilterLead>({
      query: (params) => ({
        url: '/api/leads-exits-to-campaign',
        params
      }),
      keepUnusedDataFor: 0
    }),
    getLeadInCampaign: build.query<DataResponse<LeadType[]>, string>({
      query: (id) => ({
        url: `/api/leads-exits-to-campaign/${id}`
      }),
      keepUnusedDataFor: 0
    }),
    importExcel: build.mutation<DataResponse<ImportDataType>, any>({
      query: (body) => ({
        url: '/api/leads/import-leads',
        method: 'POST',
        data: body,
        headers: { 'Content-Type': 'multipart/form-data' }
      }),
      invalidatesTags: (result, error, body) => [{ type: 'Leads', id: 'LIST' }]
    }),
    getLeadBSalesForceByOwner: build.query<DataResponse<LeadSalesForceType[]>, FilterLead>({
      query: (params) => ({
        url: `/api/sales-force/leads`,
        params
      }),
      keepUnusedDataFor: 0,
    }),
    addLeadMultiple: build.mutation<DataResponse<LeadSalesForceType[]>, Omit<LeadSalesForceType, "id">[]>({
      query: (body) => {
        const formattedBody = {
          createRows: body
        };
        return {
          url: `/api/sales-force/create-multiple`,
          method: 'POST',
          data: formattedBody,
          headers: { 'Content-Type': 'application/json' }
        };
      },
      invalidatesTags: (result, error, body) => [{ type: 'Leads', id: 'LIST' }]
    }),
    
  })
})

export const {
  useGetLeadsQuery,
  useLazyGetLeadsQuery,
  useGetDetailLeadQuery,
  useGetDetailLeadHistoryQuery,
  useAddLeadMutation,
  useUpdateLeadMutation,
  useDeleteLeadMutation,
  useGetExistLeadsQuery,
  useGetLeadInCampaignQuery,
  useImportExcelMutation,
  useGetLeadBSalesForceByOwnerQuery,
  useAddLeadMultipleMutation,
} = leadSlice
