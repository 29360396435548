import axiosBaseQuery from '@/store/axiosBaseQuery'
import { Compose } from '@/types/campaign.type'
import type {
  AboutYouType,
  BlockDomainType,
  ChatSettingType,
  CustomEmail,
  Product,
  PersonaType,
  SampleProfileType,
  SettingPayload,
  SettingType,
  PutCRMSalesForceType,
  EmailSignature,
  UploadImageEmailSignature
} from '@/types/setting.type'
import type { DataResponse } from '@/types/utils.type'
import { createApi } from '@reduxjs/toolkit/query/react'
import { omit } from 'lodash'

type ConnectionDataType = {
  user_id: string | number
  type: 'EMAIL' | 'CALENDAR' | 'SALESFORCE'
}

export const settingSlice = createApi({
  reducerPath: 'settingSlice',
  tagTypes: [
    'Settings',
    'Offering',
    'Persona',
    'About',
    'CustomEmail',
    'MichaelSetting',
    'BlockedDomain'
  ],
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getConnectionData: build.query<DataResponse<SettingType[]>, ConnectionDataType>({
      query: (params) => ({
        url:
          params?.type === 'EMAIL'
            ? '/api/external-oauth-app/emails'
            : '/api/external-oauth-app/calendars',
        params: {
          user_id: params.user_id
        },
        headers: {
          isDelay: true
        }
      }),
      providesTags: (result, _e, arg) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Settings' as const, id: id as string })),
              {
                type: 'Settings' as const,
                id: arg?.type === 'EMAIL' ? 'LIST_EMAIL' : 'LIST_CALENDAR'
              }
            ]
          : [
              {
                type: 'Settings' as const,
                id: arg?.type === 'EMAIL' ? 'LIST_EMAIL' : 'LIST_CALENDAR'
              }
            ]
    }),
    activeEmail: build.mutation<DataResponse<SettingType>, SettingPayload.ActiveEmail>({
      query: (body) => ({
        url:
          body.type === 'EMAIL'
            ? `/api/external-oauth-app/connect-email/${body.id}`
            : `/api/external-oauth-app/connect-calendar/${body.id}`,
        method: 'PUT',
        data: omit(body, 'type')
      }),
      invalidatesTags: (_r, _e, body) => [
        { type: 'Settings', id: body.type === 'EMAIL' ? 'LIST_EMAIL' : 'LIST_CALENDAR' }
      ]
    }),
    removeEmail: build.mutation<DataResponse<SettingType>, SettingPayload.RemoveEmail>({
      query: (body) => ({
        url:
          body.type === 'EMAIL'
            ? `/api/external-oauth-app/connect-email/${body.id}`
            : `/api/external-oauth-app/connect-calendar/${body.id}`,
        method: 'DELETE',
        data: {
          id: body.type
        }
      }),
      invalidatesTags: (_r, _e, arg) => [
        { type: 'Settings', id: arg.type === 'EMAIL' ? 'LIST_EMAIL' : 'LIST_CALENDAR' }
      ]
    }),
    postGoogle: build.mutation({
      query: (params) => ({
        url: `/api/google/callback?code=${params.code}`,
        method: 'POST',
        data: params.body,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }),
      invalidatesTags: (_r, _e, body) => [
        { type: 'Settings', id: body.type === 'EMAIL' ? 'LIST_EMAIL' : 'LIST_CALENDAR' }
      ]
    }),
    reconnectGoogle: build.mutation<any, string>({
      query: (id) => ({
        url: `/api/reconnect-external-auth-google/${id}`,
        method: 'PUT'
      })
    }),
    postMicrosoft: build.mutation({
      query: (params) => ({
        url: `/api/microsoft/callback?code=${params.code}`,
        method: 'POST',
        data: params.body,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    }),
    postIMAP: build.mutation<DataResponse<SettingType>, SettingPayload.PostIMAP>({
      query: (body) => ({
        url: '/api/external-oauth-app/email-credentials',
        method: 'POST',
        data: body
      }),
      invalidatesTags: (result, error, body) => [
        { type: 'Settings', id: body.type === 'EMAIL' ? 'LIST_EMAIL' : 'LIST_CALENDAR' }
      ]
    }),
    getPersona: build.query<DataResponse<PersonaType>, { user_id: string | number }>({
      query: (params) => ({
        url: '/api/personas-users',
        params: {
          user_id: params.user_id
        }
      }),
      providesTags: () => [{ type: 'Persona' as const, id: 'DETAILS' }]
    }),
    postPersona: build.mutation<DataResponse<PersonaType>, PersonaType>({
      query: (data) => ({
        url: '/api/personas',
        method: 'POST',
        data
      }),
      invalidatesTags: (result, error, body) => (error ? [] : [{ type: 'Persona', id: 'DETAILS' }])
    }),
    updatePersona: build.mutation<DataResponse<PersonaType>, PersonaType>({
      query: (data) => ({
        url: `/api/personas/${data.id}`,
        method: 'PUT',
        data
      }),
      invalidatesTags: (result, error, body) => [{ type: 'Persona', id: 'DETAILS' }]
    }),
    getSampleProfile: build.query<DataResponse<SampleProfileType[]>, PersonaType>({
      query: (params) => ({
        url: '/api/leads-from-apollos',
        params: {
          ...params,
          job_title: params?.job_title?.join(','),
          company: params?.company?.join('\n'),
          location: params?.location?.join(','),
          past_company: params?.past_company?.join(','),
          industry_keyword: params?.industry_keyword?.join(','),
          buying_intent: params?.buying_intent?.join(','),
          language: params?.language?.join(',')
        }
      })
    }),
    getBlockDomain: build.query<DataResponse<BlockDomainType[]>, void>({
      query: () => ({
        url: '/api/block-domains'
      }),
      providesTags: () => [{ type: 'BlockedDomain' as const }]
    }),
    putBlockDomain: build.mutation<DataResponse<BlockDomainType[]>, SettingPayload.BlockDomain>({
      query: (data) => ({
        url: '/api/block-domains',
        method: 'POST',
        data
      }),
      invalidatesTags: (result, error) => (error ? [] : [{ type: 'BlockedDomain' }])
    }),
    getChatSettings: build.query<DataResponse<ChatSettingType>, { user_id: string | number }>({
      query: (params) => ({
        url: '/api/michael-chats',
        params: {
          user_id: params.user_id
        }
      }),
      providesTags: (result) => {
        return [{ type: 'MichaelSetting' as const }]
      }
    }),
    postChatSettings: build.mutation<DataResponse<ChatSettingType>, ChatSettingType>({
      query: (data) => ({
        url: '/api/michael-chats',
        method: 'POST',
        data
      }),
      invalidatesTags: (result, error, body) => [{ type: 'MichaelSetting' }]
    }),
    updateChatSettings: build.mutation<DataResponse<ChatSettingType>, ChatSettingType>({
      query: (data) => ({
        url: `/api/michael-chats/${data?.id}`,
        method: 'PUT',
        data
      }),
      invalidatesTags: (result, error, body) => [{ type: 'MichaelSetting' }]
    }),
    getOfferings: build.query<DataResponse<Product.Type>, void>({
      query: () => ({
        url: '/api/company-offering'
      }),
      providesTags: () => [{ type: 'Offering' as const }]
    }),
    postOffering: build.mutation<DataResponse<Product.Type>, Product.Type>({
      query: (body) => ({
        url: '/api/company-offering',
        method: 'POST',
        data: body
      }),
      invalidatesTags: (result, error) => (error ? [] : [{ type: 'Offering' }])
    }),
    updateOffering: build.mutation<DataResponse<Product.Type>, Product.Type>({
      query: (body) => ({
        url: `/api/company-offering/${body?.id}`,
        method: 'PUT',
        data: body
      }),
      invalidatesTags: (result, error) => (error ? [] : [{ type: 'Offering' }])
    }),
    removeOffering: build.mutation<DataResponse<Product.Type>, { product_id: string | number }>({
      query: (body) => ({
        url: `/api/company-offering/${body?.product_id}`,
        method: 'DELETE',
        data: body
      }),
      invalidatesTags: (result, error) => (error ? [] : [{ type: 'Offering' }])
    }),
    getAboutYou: build.query<DataResponse<AboutYouType>, { user_id: string | number }>({
      query: (params) => ({
        url: '/api/about-you',
        params: {
          user_id: params.user_id
        }
      }),
      providesTags: () => [{ type: 'About' }]
    }),
    postAboutYou: build.mutation({
      query: (body) => ({
        url: '/api/about-you',
        method: 'POST',
        data: body
      }),
      invalidatesTags: (result, error) => (error ? [] : [{ type: 'About' }])
    }),
    getCustomEmail: build.query<DataResponse<CustomEmail.Type>, string>({
      query: (string) => ({
        url: '/api/custom-emails',
        params: {
          user_id: string
        }
      }),
      providesTags: () => [{ type: 'CustomEmail' as const }]
    }),
    addCustomEmail: build.mutation<DataResponse<CustomEmail.Type>, SettingPayload.CustomEmail>({
      query: (body) => ({
        url: '/api/custom-emails',
        method: 'POST',
        data: body
      }),
      invalidatesTags: (result, error) => (error ? [] : [{ type: 'CustomEmail' }])
    }),
    updateCustomEmail: build.mutation<DataResponse<CustomEmail.Type>, SettingPayload.CustomEmail>({
      query: (body) => ({
        url: `/api/custom-emails/${body.id}`,
        method: 'PUT',
        data: body
      }),
      invalidatesTags: (result, error) => (error ? [] : [{ type: 'CustomEmail' }])
    }),
    getSubjectLine: build.query<DataResponse<CustomEmail.SubjectLine[]>, void>({
      query: () => ({
        url: '/api/subject-line-work-flows'
      })
    }),
    getEmailOpener: build.query<DataResponse<CustomEmail.EmailOpener[]>, void>({
      query: () => ({
        url: '/api/email-opener-work-flows'
      })
    }),
    getEmailClose: build.query<DataResponse<CustomEmail.EmailClose[]>, void>({
      query: () => ({
        url: '/api/email-close-work-flows'
      })
    }),
    previewEmail: build.mutation<Compose.Data, any>({
      query: (body) => ({
        url: '/api/v1/email/compose/preview',
        method: 'POST',
        data: body,
        headers: {
          isAISite: true
        }
      })
    }),
    removeSalesForce: build.mutation<DataResponse<SettingType>, SettingPayload.RemoveCRMConnect>({
      query: (body) => ({
        url: `/api/external-oauth-app/sales-forces/${body.id}`,
        method: 'DELETE',
        data: {
          id: body.id
        }
      }),
      invalidatesTags: (_r, _e, arg) => [{ type: 'Settings', id: 'LIST_SALES_FORCE' }]
    }),
    deActiveSalesForce: build.mutation<any, PutCRMSalesForceType>({
      query: (body) => ({
        url: `/api/sales-force/deactivate/${body.id}`,
        method: 'PUT',
        data: {
          id: body.id
        }
      }),
      invalidatesTags: (_r, _e, arg) => [{ type: 'Settings', id: 'LIST_SALES_FORCE' }]
    }),
    getConnectionSalesForceData: build.query<DataResponse<SettingType[]>, ConnectionDataType>({
      query: (params) => ({
        url: `/api/external-oauth-app/sales-forces`,
        headers: {
          isDelay: true
        }
      }),
      providesTags: (result, _error, arg) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Settings' as const, id: id as string })),
              { type: 'Settings' as const, id: 'LIST_SALES_FORCE' }
            ]
          : [{ type: 'Settings' as const, id: 'LIST_SALES_FORCE' }]
    }),
    updateEmailSignature: build.mutation<any, EmailSignature>({
      query: (body) => ({
        url: `/api/me/email-signature/${body.id}`,
        method: 'PUT',
        data: body
      }),
      invalidatesTags: (_r, _e, arg) => [{ type: 'Settings', id: 'CustomEmail' }]
    }),
    getEmailSignatureData: build.query<DataResponse<EmailSignature>, ConnectionDataType>({
      query: (id) => ({
        url: `/api/me/email-signature/${id}`,
        headers: {
          isDelay: true
        }
      }),
      providesTags: () => [{ type: 'CustomEmail' as const }]
    }),
    uploadImageEmailSignature: build.mutation<any, UploadImageEmailSignature>({
      query: (body) => ({
        url: `/api/me/upload-image-email-signature`,
        method: 'POST',
        data: body
      }),
      invalidatesTags: (_r, _e, arg) => [{ type: 'Settings', id: 'CustomEmail' }],
    }),
  })
})

export const {
  useGetConnectionDataQuery,
  useLazyGetConnectionDataQuery,
  useActiveEmailMutation,
  useRemoveEmailMutation,
  usePostGoogleMutation,
  useReconnectGoogleMutation,
  usePostMicrosoftMutation,
  usePostIMAPMutation,
  useGetPersonaQuery,
  usePostPersonaMutation,
  useUpdatePersonaMutation,
  useGetSampleProfileQuery,
  useLazyGetSampleProfileQuery,
  useGetBlockDomainQuery,
  usePutBlockDomainMutation,
  useGetChatSettingsQuery,
  usePostChatSettingsMutation,
  useUpdateChatSettingsMutation,
  useGetOfferingsQuery,
  usePostOfferingMutation,
  useRemoveOfferingMutation,
  useUpdateOfferingMutation,
  useGetAboutYouQuery,
  usePostAboutYouMutation,
  useGetCustomEmailQuery,
  useAddCustomEmailMutation,
  useUpdateCustomEmailMutation,
  useGetSubjectLineQuery,
  useGetEmailOpenerQuery,
  useGetEmailCloseQuery,
  usePreviewEmailMutation,
  useRemoveSalesForceMutation,
  useDeActiveSalesForceMutation,
  useGetConnectionSalesForceDataQuery,
  useUpdateEmailSignatureMutation,
  useGetEmailSignatureDataQuery,
  useUploadImageEmailSignatureMutation
} = settingSlice